import React, {useRef, useEffect} from "react";

import {connect} from 'react-redux'

//Helpers

import About from '../../components/Emprendimiento/about'
import Units from '../../components/Emprendimiento/units'
import StickyTab from '../../components/StickyTab'

const WrapperContent = () => {
    return (
            // No borrar este article
            <article>
                <>
                
                <div className="row bg-white">
                    <div className="col-12 col-lg-8">
                        <About />
                        <Units />
                    </div>
                    <div className="d-none d-lg-block col-lg-4 pt-2">
                        <StickyTab/>
                    </div>
                </div>
                
                </>
            </article>
    )
}

export default WrapperContent;