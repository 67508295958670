import React, {useState,useEffect} from "react";
import { Link } from "gatsby"
import { connect } from "react-redux";
import { useLocation } from "@reach/router";
import Formulario from '../Contact.jsx'
import {group_Unities,getFakeAddress,getLocation} from '../../helpers/helper.developments'
import {findEnvironments} from '../../helpers/helper.unities'
import Loading from '../../components/Loading'
import $ from 'jquery' // important: case sensitive.
import Map from '../map'
import imgBase from '../../images/about-1.jpg';

const Units = (props) => {

    const location = useLocation()

    const [buttonsFilters,setButtonsFilters] = useState([])

    const [group,setGroup] = useState([])
    const [load,setLoad] = useState(false)

    const {unities} = props
    const {dispatch} = props
    const {development} = props
    const {loading} = props

    const [filters,setFilters] = useState(0)
    const [isArrowClicked, setIsArrowClicked] = useState(false)

    const query = new URLSearchParams(location.search);
    const idUnidades = query.get('unidades')?.split("-") // Obtengo IDS por URL

    const [booleanScroll,setBooleanScroll] = useState(false)

    useEffect(() => {
        if(unities){
            setGroup(group_Unities(unities,filters,idUnidades))
            setLoad(true)
        }
    },[unities,filters])

    useEffect(() => {
        if(unities){
            setButtonsFilters(findEnvironments(unities))
        }
    },[unities])

    useEffect(() => {
        if(idUnidades?.length > 0 && !booleanScroll && !loading){
            if($("#unidades")){
                $('html, body').animate({
                    scrollTop: $("#unidades").offset()?.top
                },
                500);
                setBooleanScroll(true)
            }
        }
    },[idUnidades,loading])

    const updateFilter = (value) => {
        if(value===filters){return setFilters(0)}
        return setFilters(value)
    }

    return !loading ?
        <section class={"ficha-units"} id="unidades">
            <div class="units-container">
                {( group.length > 0 || filters > 0 || idUnidades?.length > 0 ? 
                <>
                    <div className="container-fluid">
                        <div className="row">
                            <div class="col-12 mb-lg-3 pb-lg-3">
                                <div class="separator"></div>
                            </div>
                        </div>
                        <div class="row intro">
                            <div class="col-lg-12 text-center">
                                <h2 class="section-name">Unidades</h2>
                            </div>
                            {/* <div class="col-lg-12">
                                <p>Gran variedad de unidades que se adaptan <br class="d-none d-lg-block" /> a todas tus necesidades y requerimientos de búsqueda.</p>
                            </div> */}
                            <div className="col-12">
                                <div className="d-flex flex-column flex-lg-row justify-content-center mt-3">
                                    {
                                        buttonsFilters.length > 1 && <div className={"tag-filter proyect-bg me-3 mb-3 text-center " + (filters === 0 ? ' active ' : '')} onClick={() => updateFilter(0)}> Todos</div>
                                    }
                                    {
                                        buttonsFilters.length > 1
                                        && buttonsFilters.map(element => (
                                            <div className={"tag-filter proyect-bg me-3 mb-3 text-center " + (findEnvironments(unities.objects,element) ? '' : ' d-none ' ) + (filters === element ? ' active ' : '')} onClick={() => updateFilter(element)}>{element} Ambiente{element > 1 && 's'}</div>
                                        ))
                                    } 
                                </div>
                            </div>
                        </div>    
                        <div class="row">
                            <div class="col-lg-12">
                                {group && load ? 
                                    group?.map((item,index) => (
                                        <div className={"unity collapsed"} data-bs-toggle="collapse" onClick={() => setIsArrowClicked(!isArrowClicked)} data-bs-target={"#collapseExample"+index} aria-expanded="false" aria-controls={"collapseExample"+index} key={index} style={{zIndex:"5"}}>
                                        <div className="head-row row">
                                                <div className="col-1 d-none d-lg-block text-center">
                                                    <i className="icon-gallery-light"></i>
                                                </div>
                                                <div className="col-3 col-lg-2">
                                                    <span className="unity-font" style={{paddingLeft: '0.7rem'}}>{item.floor}</span>
                                                </div>
                                                <div className="col-2 d-none d-lg-block" style={{textAlign:'center'}}>
                                                    <span className="unity-font sub-title" style={{width:'fit-content',margin:"0 auto",paddingLeft: '0.7rem'}}>Ambientes</span>
                                                </div>
                                                <div className="col-2 d-none d-lg-block" style={{textAlign:"center"}}>
                                                    <span className="unity-font sub-title" style={{width:'fit-content',margin:"0 auto",paddingLeft: '0.7rem'}}>Sup. cubierta </span>
                                                </div>
                                                <div className="col-3 col-lg-2" style={{textAlign:"center"}}>
                                                    <span className="unity-font sub-title" style={{width:'fit-content',margin:"0 auto",paddingLeft: '0.7rem'}}>Sup. Total </span>
                                                </div>
                                                <div className="col-3 col-lg-2" style={{textAlign:"center"}}>
                                                    <span className="unity-font sub-title" style={{width:'fit-content',margin:"0 auto",paddingLeft: '0.7rem'}}>Precio </span>
                                                </div>
                                                <div className="col-3 col-lg-1" style={{display:"flex",alignItems:"center",justifyContent:"flex-end"}}>
                                                <i className={'icon-arrow'}></i>
                                            </div>
                                            </div>
                                            <div className="close-deployable collapse pb-3" id={"collapseExample"+index}>
                                                {item.unities?.sort((a, b) => a.address.split(" ")[1]?.localeCompare(b.address.split(" ")[1])).map((element,index) => (                // SOLUCIÓN                         
                                                    (element.status === 2 ?
                                                    <>
                                                        <a target="_blank" href={"/propiedad/" + element.operation.toLowerCase() + "/" + element.id}>
                                                        {/* <Link to={"/propiedad/" + element.operation.toLowerCase() + "/" + element.id}> */}
                                                            <div className={"unity-row unit row align-items-center " + (element.status !== 2 ? 'disabled' : '')} >
                                                                <div className="col-1 d-none d-lg-block">
                                                                    <img src={development?.photos[0]?.image} className="img-thumb" alt="Image thumbnail" /> 
                                                                </div>
                                                                <div className="col-3 col-lg-2">
                                                                    <span className={"unity-yellow-font montserrat-regular proyect-color"}>{element.address}</span>
                                                                </div>
                                                                <div className="col-2 d-none d-lg-block" style={{textAlign:'center'}}>
                                                                    <span className="unity-font montserrat-regular proyect-color" style={{width:'fit-content',margin:"0 auto"}}>{element.rooms}<sup></sup></span>
                                                                </div>
                                                                <div className="col-lg-2 col-4 d-none d-lg-block" style={{textAlign:"center"}}>
                                                                    <span className="unity-font montserrat-regular proyect-color" style={{width:'fit-content',margin:"0 auto"}}>{element.surface} m<sup>2</sup></span>
                                                                </div>
                                                                <div className="col-3 col-lg-2" style={{textAlign:"center"}}>
                                                                    <span className="unity-font montserrat-regular proyect-color" style={{width:'fit-content',margin:"0 auto"}}>{element.surface_total} m<sup>2</sup></span>
                                                                </div>
                                                                <div className="col-3 col-lg-2" style={{textAlign:"center"}}>
                                                                    <span className="unity-font montserrat-regular proyect-color" style={{width:'fit-content',margin:"0 auto"}}> {element.currency} {element.price > 0 ? new Intl.NumberFormat("de-DE").format(element.price) : element.price}</span>
                                                                </div>
                                                                {/* <div className="col-3 d-lg-none d-block"></div> */}
                                                                <div onClick={() => window?.open("/propiedad/" + element.operation.toLowerCase() + "/" + element.id)} className="col-3 col-lg-1 round"><i className="icon-plus-light"></i></div>
                                                            </div>
                                                        {/* </Link> */}
                                                        </a>
                                                    </>
                                                :
                                                    <>
                                                        <a href={"/propiedad/" + element.operation.toLowerCase() + "/" + element.id} target="_blank" >
                                                        {/* <Link to={"/propiedad/" + element.operation.toLowerCase() + "/" + element.id}> */}
                                                            <div className={"unity-row unit row disabled"} >
                                                                <div className="col-lg-6 col-3">
                                                                    <span className={"unity-yellow-font proyect-color"}>{element.address}  </span>
                                                                </div>
                                                                <div className="col-lg-2 col-3" style={{textAlign:'center',transform:"rotate(0deg)"}}>
                                                                    <span className="unity-font" style={{minWidth:'5rem',textAlign:"center"}}>{element.rooms}<sup></sup></span>
                                                                </div>
                                                                <div className="col-lg-3 col-4 d-none d-lg-block" style={{textAlign:"center"}}>
                                                                    <span className="unity-font" style={{minWidth:'5rem',textAlign:"center"}}>{element.surface} m<sup>2</sup></span>
                                                                </div>
                                                                <div className="col-lg-2 col-4 d-none d-lg-block" style={{textAlign:"center"}}>
                                                                    <span className="unity-font" style={{minWidth:'5rem',textAlign:"center"}}>{element.surface_total} m<sup>2</sup></span>
                                                                </div>
                                                                <div className="col-lg-3 col-4" style={{textAlign:"center"}}>
                                                                    <span className="unity-font" style={{minWidth:'5rem',textAlign:"center"}}> {element.currency} {new Intl.NumberFormat("de-DE").format(element.price)}</span>
                                                                </div>
                                                                <div className="col-3 col-lg-1 round"><a href={"/propiedad/" + element.operation.toLowerCase() + "/" + element.id} target="_blank" className="icon-plus-light"></a></div>
                                                            </div>
                                                        {/* </Link> */}
                                                        </a>
                                                    </>
                                                )
                                            ))}
                                            </div>
                                        </div>
                                    ))
                                :''
                                }
                                <div className={"col-10 m-auto text-center" + (group.length === 0 ? '  ' : " d-none") }>
                                    <p className="disabled-unities text-center" style={{fontSize:"1rem",margin: "0rem 0", maxWidth: ""}}>No hay resultados para tu busqueda.</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div class="col-12 mb-3 pb-3">
                                <div class="separator"></div>
                            </div>
                        </div> */}
                    </div>
                </>
                :'')}
                <div class="ubicacion-main px-lg-5">
                    <div class="row intro">
                        <div className="container-fluid text-center">
                            <div class="col-12"><div class="separator mt-3"></div></div>
                            <div class="col-lg-12">
                                <h2 class="section-name mt-5 pt-3">Ubicación</h2>
                            </div>
                            <div class="col-lg-12">
                                <p>
                                { getFakeAddress(development) + ", " + getLocation(development,true)[0] + (getLocation(development,true).length > 2 ? (" - " + getLocation(development,true)[1]) : '')}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <Map coordenadas={{ geo_lat: development.geo_lat, geo_long: development.geo_long }} />
                        </div>
                    </div>
                </div>  
            </div>
        </section>
    :
    <>
        {/* <Loading absolute={true}/> */}
    </>
}       

export default connect(state => ({
    unities: state.developments.unities,
    development: state.developments.development,
    loading: state.developments.loading,
}),null)(Units);