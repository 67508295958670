import React,{ useRef, useState} from 'react'
import { connect } from 'react-redux'
import Breadcrumbs from "../breadcrumbs";
import SimpleReactLightbox, { SRLWrapper }from 'simple-react-lightbox'
import { graphql, useStaticQuery } from 'gatsby';
import Slider from "react-slick";
import FsLightbox from 'fslightbox-react';

//Helpers
import { getStatus, getContructionDate, getName, getTitle, getBetweenSupTotal, getBetweenSupCubierta, getBetweenEnvironments, getBetweenBathrooms, getCountParking  } from '../../helpers/helper.developments';
import {getDescription,getTextShort,getMessageWhatsapp, getPhotos} from '../../helpers/helper.rendering'
import {setTogglerImages, setTogglerVideos, setTogglerTours, setTogglerBlueprints, setCurrentlyBlueprint} from "../../redux/propertiesDucks.js"

import {getTour, getBluePrints, getVideos, getVideo, getFakeAddres} from '../../helpers/helper.properties'

const optionsNewSlider = {
    loop:true,
    smartSpeed:1000,
    stagePadding:0,
    center:true,
    margin:0,
    dots: true,
    dotsEach: true,
    startPosition:1,
    items:3,
    responsive: {
        0: {
            autoWidth:false,
            items: 1,
        },
        400: {
            autoWidth:false,
            items: 1,
        },
        600: {
            autoWidth:false,
            items: 1,
        },
        991: {
            autoWidth:false,
            items: 1,
        },
        992: {
            items: 1,
        }
    },
}
const options = {
    settings: {
        overlayColor: "rgba(255, 255, 255, 1)",
        autoplaySpeed: 1500,
        transitionSpeed: 900,
    },
    buttons: {
        backgroundColor: "rgba(0, 0, 0, 0)",
        iconColor: "rgba(0,0,0, 1)",
        showAutoplayButton: false,
        showCloseButton: true,
        showDownloadButton: false,
        showFullscreenButton: true,
        showNextButton: true,
        showPrevButton: true,
        showThumbnailsButton: false,
    },
    caption: {
        captionColor: "#000",
        captionFontFamily: "Raleway, sans-serif",
        captionFontWeight: "300",
        captionTextTransform: "uppercase",
    },
    thumbnails: {
        showThumbnails: false,
      }
};
const settingsSlick = {
    dots: false,
    infinite: true,
    speed: 500,
    fade:false,
    slidesToShow: 1,
    slidesToScroll: 1
  };
const About_DEV = ({development,unities,loading,togglerImages,togglerVideos,togglerTours,togglerBlueprints,currentlyImage,currentlyBluePrint,dispatch,settings}) => {

    const { realEstate } = useStaticQuery(graphql`
    query {
        realEstate {
            name
            global_whatsapp
        }
    }`)


    
    const sliderNew = useRef()
    const sliderNewTour = useRef()
    const sliderNewPlano = useRef()

    const [positionVideo,setPositionVideo] = useState(1);
    const [positionTour,setPositionTour] = useState(1);
    const [positionBluePrint,setPositionBluePrint] = useState(1);


    return !loading ? (
        <section class="ficha-about dev">
            <div class="concepto">
                <div class="row intro no-gutters pb-5 pt-4">
                    <div className="col-12 col-lg-8 offset-lg-2 order-lg-1 order-2 text-center">
                        <Breadcrumbs
                            props={[
                            { name: realEstate.name, route: "/", location: "" },
                            { name: "Emprendimientos", route:   "/emprendimientos", location: ""},
                            { name: getTextShort(getName(development), 20), route: "", location: ""},
                            ]}
                        />
                    </div>
                    <div class="col-12 order-3 text-center">
                        <h2>{getTitle(development)}</h2>
                    </div>
                </div>
            </div>
            <div class="ubicacion-main">
                <div class="container-fluid">
                    <div class="row intro pt-0">
                        <div className="tag-wrapper text-center d-flex">
                            {getStatus(development)?.length >= 1 ?
                                <span className="mb-2 tag me-2 property">{getStatus(development)}</span>
                            : ""
                            }
                            
                            {getContructionDate(development) ?
                                <span className="mb-2 tag me-2 property-type">Entrega: {getContructionDate(development)}</span>
                            : ""
                            }
                        </div>
                        {unities &&
                            <div className="col-12 my-4 py-3 py-lg-5 ">
                                <div className="">
                                    <div style={{flexWrap:"wrap"}} class={"justify-content-center div-item-amenities feature-wrapper " + (unities.length === 0 ? 'd-none' : 'd-flex') }>
                                        <div class="d-flex flex-column item-amenities align-items-center mb-lg-0 mb-5 me-lg-4 feature resp-width text-center">
                                                <i class="icon-unidades-circular"></i>
                                                <p className="text-icons w-100 flex-column align-items-center">
                                                  Unidades disponibles <br /> {unities.length}
                                                </p>
                                            </div>
                                        {/* } */}
                                            <div class={" flex-column item-amenities align-items-center mb-lg-0 mb-5 me-lg-4 feature resp-width text-center "  + (getBetweenSupTotal(unities).max === 0 ? 'd-none' : 'd-flex')}>
                                                <i class="icon-superficie-circular"></i>
                                                <p className="text-icons">
                                                    Sup. total <br /> {getBetweenSupTotal(unities).min} a {getBetweenSupTotal(unities).max} m2
                                                </p>
                                            </div>
                                        {/* } */}
                                            <div class={" flex-column item-amenities align-items-center mb-lg-0 mb-5 me-lg-4 feature resp-width text-center "  + (getBetweenSupCubierta(unities).max === 0 ? 'd-none' : 'd-flex')}>
                                                <i class="icon-cubierta-circular"></i>
                                                <p className="text-icons">
                                                    Sup. cubierta <br /> {getBetweenSupCubierta(unities).min} a {getBetweenSupCubierta(unities).max} m2
                                                </p>
                                            </div>
                                        {/* } */}
                                            <div class={" flex-column item-amenities align-items-center mb-lg-0 mb-5 me-lg-4 feature resp-width text-center " + (getBetweenEnvironments(unities).max === 0 ? 'd-none' : 'd-flex')}>
                                                <i class="icon-ambientes-circular"></i>
                                                <p className="text-icons">
                                                    Ambiente{getBetweenEnvironments(unities).max > 1 ? 's' : ''} <br />
                                                    {getBetweenEnvironments(unities).min === getBetweenEnvironments(unities).max 
                                                        ? getBetweenEnvironments(unities).max 
                                                        : getBetweenEnvironments(unities).min + " a " + getBetweenEnvironments(unities).max} 
                                                </p>
                                            </div>
                                        {/* } */}
                                            <div class={" flex-column item-amenities align-items-center mb-lg-0 mb-5 me-lg-4 feature resp-width text-center " + ((getBetweenBathrooms(unities).max === 0 ? 'd-none' : 'd-flex') )}>
                                                <i class="icon-banios-circular"></i>
                                                <p className="text-icons">
                                                    Baño{getBetweenBathrooms(unities).max > 1 ? 's' : ''} <br />
                                                    {getBetweenBathrooms(unities).min === getBetweenBathrooms(unities).max
                                                        ?   getBetweenBathrooms(unities).min
                                                        :   getBetweenBathrooms(unities).min + " a " + getBetweenBathrooms(unities).max} 
                                                </p>
                                            </div>
                                        {/* } */}
                                            <div class={" flex-column item-amenities align-items-center mb-lg-0 mb-5  me-lg-4 feature resp-width text-center " + (getCountParking(unities) === 0 ? 'd-none' : 'd-flex') }>
                                                <i class="icon-cocheras-circular"></i>
                                                <p className="text-icons w-100 flex-column align-items-center">
                                                    Cocheras disponibles <br /> {getCountParking(unities)}
                                                </p>
                                            </div>
                                        {/* } */}
                                    </div>
                                </div>
                            </div>
                        }
                        <div class="col-lg-12">
                            <p class="description-ficha text-justify">
                                {getDescription(development,99999).map((description,index) => (
                                    <>
                                        {description}{" "}
                                    </>
                                ))}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* VIDEOS */}
            <div id="video" class={"row global-view px-lg-5 not-padding " + (getVideos(development)?.length > 0 ? '' : ' d-none')}>
                    <div class="col-12">
                        <div class="separator"></div>
                    </div>
                    <div id="slider-section" className="developments-gallery-wrapper">
                        <span className="conPag">{positionVideo} / { getVideos(development).length}</span>
                        <Slider afterChange={(e) => setPositionVideo(e+1)} ref={sliderNew} {...settingsSlick}>
                            {development?.videos?
                                getVideos(development).map( (video, i) => (
                                <div class="col-12">
                                    <div class="vista">
                                    <div class=" text-center intro position-relative">
                                        <h2 class="mb-5 mx-auto">{video.title}</h2>
                                    </div>
                                    <div className='div_iframe position-relative'>
                                        <iframe allowfullscreen src={video.player_url ? video.player_url + "?controls=0&autoplay=0&rel=0&showinfo=0&modestbranding=1": ''} frameborder="0" showinfo="0" rel="0"></iframe> 
                                    </div>
                                    </div>
                                </div>  
                                )): "" }
                        </Slider>
                        {/* <div className={"arrows-owl"  + (getVideos(development)?.length === 1 ? ' d-none' : '')}>
                            <div className="arrow" onClick={() => sliderNew.current.prev()}> <i></i> <i></i></div>
                            <div className="arrow reverse" onClick={() => sliderNew.current.next()}> <i></i> <i></i> </div>
                        </div> */}
                        <div className={"arrows-owl-isq zindex-1"  + (getVideos(development)?.length === 1 || positionVideo === 1 ? ' d-none' : '')}>
                            <div className="arrow" onClick={() => sliderNew.current.slickPrev()}> <i></i> <i></i></div>
                        </div>
                        <div className={"arrows-owl-der zindex-1"  + (getVideos(development)?.length === 1 || positionVideo === getVideos(development)?.length ? ' d-none' : '')}>
                            <div className="arrow" onClick={() => sliderNew.current.slickNext()}> <i></i> <i></i> </div>
                        </div>
                    </div>
                    
                </div>
            {/* TOUR */}
            <div id="tour360" class={"row global-view px-lg-5 not-padding " + (getTour(development)?.length > 0 ? '' : ' d-none')}>
                <div class="col-12">
                    <div class="separator"></div>
                </div>
                <div id="slider-section" className="developments-gallery-wrapper">
                    <span className="conPag">{positionTour} / { getTour(development)?.length}</span>
                    <Slider afterChange={(e) => setPositionTour(e+1)} ref={sliderNewTour} {...settingsSlick}>
                        {development?.videos?
                            getTour(development)?.map( (video, i) => (
                            <div class="col-12">
                                <div class="vista">
                                <div class=" text-center intro position-relative">
                                        {/* <span className="conPag">{i + 1}/{getTour(development)?.length}</span> */}
                                    <h2 class="mb-5 mx-auto">{video.title}</h2>
                                </div>
                                <div className='div_iframe position-relative'>
                                    <iframe allowfullscreen src={video.player_url ? video.player_url : ''} frameborder="0" showinfo="0" rel="0"></iframe> 
                                </div>
                                </div>
                            </div>  
                            )): "" }
                    </Slider>
                    {/* <div className={"arrows-owl zindex-1" + (getTour(development)?.length === 1 ? ' d-none' : '')}>
                        <div className="arrow" onClick={() => sliderNewTour.current.prev()}> <i></i> <i></i></div>
                        <div className="arrow reverse" onClick={() => sliderNewTour.current.next()}> <i></i> <i></i> </div>
                    </div> */}
                    <div className={"arrows-owl-isq zindex-1"  + (getTour(development)?.length === 1 || positionTour === 1 ? ' d-none' : '')}>
                        <div className="arrow" onClick={() => sliderNewTour.current.slickPrev()}> <i></i> <i></i></div>
                    </div>
                    <div className={"arrows-owl-der zindex-1"  + (getTour(development)?.length === 1 || positionTour === getTour(development)?.length ? ' d-none' : '')}>
                        <div className="arrow" onClick={() => sliderNewTour.current.slickNext()}> <i></i> <i></i> </div>
                    </div>
                </div>
            </div>
            {/* PLANOS */}
            <div id="planos" class={"row global-view px-lg-5 not-padding " + (getBluePrints(development)?.length > 0 ? '' : ' d-none')}>
                <div class="col-12">
                    <div class="separator"></div>
                </div>
                <div id="slider-section" className="developments-gallery-wrapper">
                    <span className="conPag">{positionBluePrint} / {getBluePrints(development).length}</span>
                        <Slider afterChange={(e) => setPositionBluePrint(e+1)} ref={sliderNewPlano} {...settingsSlick}>
                            { getBluePrints(development).map( (blueprint,index) => (
                                <div class="col-12">
                                    <div class="vista">
                                    <div class=" text-center intro position-relative">
                                        <h2 class={"mb-5 mx-auto " + (blueprint.description ? '' : 'opacity')}>{blueprint.description || "Plano"}</h2>
                                    </div>
                                        <div class="plano">
                                            <img class="d-lg-block" style={{position:"relative",zIndex:"1",cursor:"pointer"}} src={blueprint.original} alt={realEstate?.name} onClick={() => dispatch(setCurrentlyBlueprint(index + 1)) + dispatch(setTogglerBlueprints())}/>
                                        </div>

                                    </div>
                                </div>  
                                ))}
                        </Slider>
                    <div className={"arrows-owl-isq zindex-1"  + (getBluePrints(development)?.length === 1 || positionBluePrint === 1 ? ' d-none' : '')}>
                        <div className="arrow" onClick={() => sliderNewPlano.current.slickPrev()}> <i></i> <i></i></div>
                    </div>
                    <div className={"arrows-owl-der zindex-1"  + (getBluePrints(development)?.length === 1 || positionBluePrint === getBluePrints(development)?.length ? ' d-none' : '')}>
                        <div className="arrow" onClick={() => sliderNewPlano.current.slickNext()}> <i></i> <i></i> </div>
                    </div>
                </div>
                
            </div>
            <div className="multimediaDiv">
                    <div className="multimediaRow d-flex">
                        {getPhotos(development).length === 1 ?
                            <div onClick={() => dispatch(setCurrentlyImage(1)) + dispatch(setTogglerImages())} className="multimediaBar d-flex">
                                <i className="icon-gallery-light"></i>
                                <p className="d-none d-lg-block">Foto</p>
                            </div>
                            :
                            <div data-bs-toggle="modal" data-bs-target="#galleryModal" className="multimediaBar d-flex">
                                <i className="icon-gallery-light"></i>
                                <p className="d-none d-lg-block">Foto</p>
                            </div>
                        }
                        <div onClick={() => dispatch(setTogglerVideos())} className={"multimediaBar " + (getVideo(development).length === 0 ? ' d-none ' : ' d-flex ')}>
                            <i className="icon-youtube-light"></i>
                            <p className="d-none d-lg-block">Video</p>
                        </div>
                        <div onClick={() => dispatch(setTogglerTours())} className={"multimediaBar " + (getTour(development,true).length === 0 ? ' d-none ' : ' d-flex ')}>
                            <i className="icon-360"></i>
                            <p className="d-none d-lg-block">Tour</p>
                        </div>
                        <div onClick={() => dispatch(setCurrentlyBlueprint(1)) + dispatch(setTogglerBlueprints())} className={"multimediaBar " + (getBluePrints(development).length === 0 ? ' d-none ' : ' d-flex ')}>
                            <i className="icon-planos_mesa_trabajo"></i>
                            <p className="d-none d-lg-block">Planos</p>
                        </div>
                        <div className="multimediaBar d-flex">
                            <div className="links-wrapper">
                                <div class="accordion" id="accordionShare">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingOne">
                                            <button class="accordion-button collapsed d-flex" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                <span className="d-flex">
                                                    <i class="icon-share"></i>
                                                </span>
                                                <p className="d-none d-lg-block">Compartir</p>   
                                            </button>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionShare">
                                            <div class="accordion-body">
                                                <span className="link-wp">
                                                    <a href={
                                                        "https://api.whatsapp.com/send?text=" +
                                                        getMessageWhatsapp(development, {}, location?.href,true)}
                                                        target="_blank">
                                                        <i class="icon-whatsapp"></i>
                                                    </a>
                                                </span>
                                                <span className="link-email">
                                                    <a href={`mailto:?Subject=${getFakeAddres(development)}&body=${getMessageWhatsapp(development, {}, location?.href,true)}`} target="_blank">
                                                        <i class="icon-email"></i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <FsLightbox
                toggler={togglerImages}
                key={0}
                slide={currentlyImage}
                sources={getPhotos(development).map(element => (
                    <>
                    <img src={element.original} />
                    <div className="multimediaDiv out ">
                        <div className="multimediaRow d-flex">
                            {getPhotos(development).length === 1 ?
                                <div onClick={() => dispatch(setCurrentlyImage(1)) + dispatch(setTogglerImages())} className="multimediaBar active d-flex">
                                    <i className="icon-gallery-light"></i>
                                    <p className="d-none d-lg-block">Foto</p>
                                </div>
                                :
                                <div data-bs-toggle="modal" data-bs-target="#galleryModal" className="multimediaBar active d-flex">
                                    <i className="icon-gallery-light"></i>
                                    <p className="d-none d-lg-block">Foto</p>
                                </div>
                            }
                            <div onClick={() => dispatch(setTogglerVideos())} className={"multimediaBar " + (getVideo(development).length === 0 ? ' d-none ' : ' d-flex ')}>
                                <i className="icon-youtube-light"></i>
                                <p className="d-none d-lg-block">Video</p>
                            </div>
                            <div onClick={() => dispatch(setTogglerTours())} className={"multimediaBar " + (getTour(development,true).length === 0 ? ' d-none ' : ' d-flex ')}>
                                <i className="icon-360"></i>
                                <p className="d-none d-lg-block">Tour</p>
                            </div>
                            <div onClick={() => dispatch(setCurrentlyBlueprint(1)) + dispatch(setTogglerBlueprints())} className={"multimediaBar " + (getBluePrints(development).length === 0 ? ' d-none ' : ' d-flex ')}>
                                <i className="icon-planos_mesa_trabajo"></i>
                                <p className="d-none d-lg-block">Planos</p>
                            </div>
                            <div className="multimediaBar d-flex ">
                                    <div className="links-wrapper">
                                        <div class="accordion accordion-hover" id="accordionShare">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingOne">
                                                    <button class="accordion-button collapsed d-flex" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        <span className="">
                                                            <i class="icon-share"></i>
                                                        </span>
                                                        <p className="d-none d-lg-block">Compartir</p>   
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionShare">
                                                    <div class="accordion-body">
                                                        <span className="link-wp">
                                                            <a href={
                                                                "https://api.whatsapp.com/send?text=" +
                                                                getMessageWhatsapp(development, {}, location?.href,true)}
                                                                target="_blank">
                                                                <i class="icon-whatsapp"></i>
                                                            </a>
                                                        </span>
                                                        <span className="link-email">
                                                            <a href={`mailto:?Subject=${getFakeAddres(development)}&body=${getMessageWhatsapp(development, {}, location?.href,true)}`} target="_blank">
                                                                <i class="icon-email"></i>
                                                            </a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    </>
                ))}
            />
            <FsLightbox
                toggler={togglerVideos}
                sources={getVideo(development).map(element => (
                    <>
                        <iframe className={"tour-width"} allowfullscreen src={element.player_url} width={"70vw"} height={"80vh"} frameborder="0" />
                        <div className="multimediaDiv out">
                            <div className="multimediaRow d-flex">
                                {getPhotos(development).length === 1 ?
                                    <div onClick={() => dispatch(setCurrentlyImage(1)) + dispatch(setTogglerImages())} className="multimediaBar d-flex">
                                        <i className="icon-gallery-light"></i>
                                        <p className="d-none d-lg-block">Foto</p>
                                    </div>
                                    :
                                    <div data-bs-toggle="modal" data-bs-target="#galleryModal" className="multimediaBar d-flex">
                                        <i className="icon-gallery-light"></i>
                                        <p className="d-none d-lg-block">Foto</p>
                                    </div>
                                }
                                <div onClick={() => dispatch(setTogglerVideos())} className={"multimediaBar active " + (getVideo(development).length === 0 ? ' d-none ' : ' d-flex ')}>
                                    <i className="icon-youtube-light"></i>
                                    <p className="d-none d-lg-block">Video</p>
                                </div>
                                <div onClick={() => dispatch(setTogglerTours())} className={"multimediaBar " + (getTour(development,true).length === 0 ? ' d-none ' : ' d-flex ')}>
                                    <i className="icon-360"></i>
                                    <p className="d-none d-lg-block">Tour</p>
                                </div>
                                <div onClick={() => dispatch(setCurrentlyBlueprint(1)) + dispatch(setTogglerBlueprints())} className={"multimediaBar " + (getBluePrints(development).length === 0 ? ' d-none ' : ' d-flex ')}>
                                    <i className="icon-planos_mesa_trabajo"></i>
                                    <p className="d-none d-lg-block">Planos</p>
                                </div>
                                <div className="multimediaBar d-flex">
                                    <div className="links-wrapper">
                                        <div class="accordion accordion-hover" id="accordionShare">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingOne">
                                                    <button class="accordion-button collapsed d-flex" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        <span className="">
                                                            <i class="icon-share"></i>
                                                        </span>
                                                        <p className="d-none d-lg-block">Compartir</p>   
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionShare">
                                                    <div class="accordion-body">
                                                        <span className="link-wp">
                                                            <a href={
                                                                "https://api.whatsapp.com/send?text=" +
                                                                getMessageWhatsapp(development, {}, location?.href,true)}
                                                                target="_blank">
                                                                <i class="icon-whatsapp"></i>
                                                            </a>
                                                        </span>
                                                        <span className="link-email">
                                                            <a href={`mailto:?Subject=${getFakeAddres(development)}&body=${getMessageWhatsapp(development, {}, location?.href,true)}`} target="_blank">
                                                                <i class="icon-email"></i>
                                                            </a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ))}
            />
            <FsLightbox
                toggler={togglerBlueprints}
                sources={getBluePrints(development)?.map(element => (
                    <>
                        <img src={element.original} />
                        <div className="multimediaDiv out">
                            <div className="multimediaRow d-flex">
                                {getPhotos(development).length === 1 ?
                                    <div onClick={() => dispatch(setCurrentlyImage(1)) + dispatch(setTogglerImages())} className="multimediaBar d-flex">
                                        <i className="icon-gallery-light"></i>
                                        <p className="d-none d-lg-block">Foto</p>
                                    </div>
                                    :
                                    <div data-bs-toggle="modal" data-bs-target="#galleryModal" className="multimediaBar d-flex">
                                        <i className="icon-gallery-light"></i>
                                        <p className="d-none d-lg-block">Foto</p>
                                    </div>
                                }
                                <div onClick={() => dispatch(setTogglerVideos())} className={"multimediaBar " + (getVideo(development).length === 0 ? ' d-none ' : ' d-flex ')}>
                                    <i className="icon-youtube-light"></i>
                                    <p className="d-none d-lg-block">Video</p>
                                </div>
                                <div onClick={() => dispatch(setTogglerTours())} className={"multimediaBar " + (getTour(development,true).length === 0 ? ' d-none ' : ' d-flex ')}>
                                    <i className="icon-360"></i>
                                    <p className="d-none d-lg-block">Tour</p>
                                </div>
                                <div onClick={() => dispatch(setCurrentlyBlueprint(1)) + dispatch(setTogglerBlueprints())} className={"multimediaBar active " + (getBluePrints(development).length === 0 ? ' d-none ' : ' d-flex ')}>
                                    <i className="icon-planos_mesa_trabajo"></i>
                                    <p className="d-none d-lg-block">Planos</p>
                                </div>
                                <div className="multimediaBar d-flex">
                                    <div className="links-wrapper">
                                        <div class="accordion accordion-hover" id="accordionShare">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingOne">
                                                    <button class="accordion-button collapsed d-flex" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                                        <span className="">
                                                            <i class="icon-share"></i>
                                                        </span>
                                                        <p className="d-none d-lg-block">Compartir</p>   
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionShare">
                                                    <div class="accordion-body">
                                                        <span className="link-wp">
                                                            <a href={
                                                                "https://api.whatsapp.com/send?text=" +
                                                                getMessageWhatsapp(development, {}, location?.href,true)}
                                                                target="_blank">
                                                                <i class="icon-whatsapp"></i>
                                                            </a>
                                                        </span>
                                                        <span className="link-email">
                                                            <a href={`mailto:?Subject=${getFakeAddres(development)}&body=${getMessageWhatsapp(development, {}, location?.href,true)}`} target="_blank">
                                                                <i class="icon-email"></i>
                                                            </a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ))}
                slide={currentlyBluePrint}
                key={2}
                showThumbsOnMount={true}
                slideChangeAnimation="scale-in"
            />
            <FsLightbox
                toggler={togglerTours}
                sources={getTour(development,true)?.map((element) => (
                    <>
                        <iframe className={"tour-width"} allowfullscreen src={element.player_url} width={"70vw"} height={"80vh"} frameborder="0" />
                        <div className="multimediaDiv out">
                            <div className="multimediaRow d-flex">
                                {getPhotos(development).length === 1 ?
                                    <div onClick={() => dispatch(setCurrentlyImage(1)) + dispatch(setTogglerImages())} className="multimediaBar d-flex">
                                        <i className="icon-gallery-light"></i>
                                        <p className="d-none d-lg-block">Foto</p>
                                    </div>
                                    :
                                    <div data-bs-toggle="modal" data-bs-target="#galleryModal" className="multimediaBar d-flex">
                                        <i className="icon-gallery-light"></i>
                                        <p className="d-none d-lg-block">Foto</p>
                                    </div>
                                }
                                <div onClick={() => dispatch(setTogglerVideos())} className={"multimediaBar " + (getVideo(development).length === 0 ? ' d-none ' : ' d-flex ')}>
                                    <i className="icon-youtube-light"></i>
                                    <p className="d-none d-lg-block">Video</p>
                                </div>
                                <div onClick={() => dispatch(setTogglerTours())} className={"multimediaBar active " + (getTour(development,true).length === 0 ? ' d-none ' : ' d-flex ')}>
                                    <i className="icon-360"></i>
                                    <p className="d-none d-lg-block">Tour</p>
                                </div>
                                <div onClick={() => dispatch(setCurrentlyBlueprint(1)) + dispatch(setTogglerBlueprints())} className={"multimediaBar  " + (getBluePrints(development).length === 0 ? ' d-none ' : ' d-flex ')}>
                                    <i className="icon-planos_mesa_trabajo"></i>
                                    <p className="d-none d-lg-block">Planos</p>
                                </div>
                                <   div className="multimediaBar d-flex">
                                    <div className="links-wrapper">
                                        <div class="accordion accordion-hover" id="accordionShare">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingOne">
                                                    <button class="accordion-button collapsed d-flex" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        <span className="">
                                                            <i class="icon-share"></i>
                                                        </span>
                                                        <p className="d-none d-lg-block">Compartir</p>   
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionShare">
                                                    <div class="accordion-body">
                                                        <span className="link-wp">
                                                            <a href={
                                                                "https://api.whatsapp.com/send?text=" +
                                                                getMessageWhatsapp(development, {}, location?.href,true)}
                                                                target="_blank">
                                                                <i class="icon-whatsapp"></i>
                                                            </a>
                                                        </span>
                                                        <span className="link-email">
                                                            <a href={`mailto:?Subject=${getFakeAddres(development)}&body=${getMessageWhatsapp(development, {}, location?.href,true)}`} target="_blank">
                                                                <i class="icon-email"></i>
                                                            </a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ))}
            />
        </section>
    ):
    <>
      {/* <Loading absolute={true}/> */}
    </>
}

export default connect(state => ({
    settings: state.settings,
    development: state.developments.development,
    loading: state.developments.loading,
    unities: state.developments.unities,
    currentlyImage:state.properties.currentlyImage,
    currentlyBluePrint:state.properties.currentlyBluePrint,
    togglerImages: state.properties.togglerImages,
    togglerVideos: state.properties.togglerVideos,
    togglerTours: state.properties.togglerTours,
    togglerBlueprints: state.properties.togglerBlueprints,
}),null)(About_DEV);