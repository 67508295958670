import React, {useEffect} from "react";
import Layout from "../../components/layout.js"

import { connect } from "react-redux"

import {getDevelopmentAction, getUnitsDevelopmentAction} from '../../redux/developmentsDucks'
import WrapperContent from "../../components/Emprendimiento/wrapperContent";
import MobileStickyTab from "../../components/mobileStickyTab";
import Main from "../../components/Emprendimiento/main" 
import { navigate } from "gatsby";


const Development = (props) => {


  const {loading} = props
  const {loading_unities} = props
  const {api_key} = props
  const {dispatch} = props
  const {id} = props
  // const {serverData} = props

  useEffect(() => {
    if(api_key && id){
      dispatch(getDevelopmentAction(id)).catch(error => {
        console.error(error)
        if (error.response && error.response.status === 404) {
          navigate("/404")
        }
      })
      dispatch(getUnitsDevelopmentAction(id)).catch(error => {
        console.error(error)
        if (error.response && error.response.status === 404) {
          navigate("/404")
        }
      })
    }
  },[api_key,id])

  const isSSR = typeof window === "undefined"

	return (
        <Layout>
          <article>
            {!isSSR && <Main />}
            <WrapperContent />
            <MobileStickyTab/>
          </article>
        </Layout> 
    )
}
export default connect(state => ({
    loading: state.developments.loading,
    loading_unities: state.developments.loading_unities,
    api_key: state.settings.keys.tokko
}),null)(Development);