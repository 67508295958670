import React, {useRef} from "react"
import {connect} from 'react-redux'
import { useLocation } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'

//Helpers
import { getFakeAddress } from '../../helpers/helper.developments'

// Components
import Loading from '../../components/Loading'
import GridGalleryModal from "../GridGalleryModal"

const Main = ({development,loading,loading_unities,api_key,dispatch}) => {
    
    const slider = useRef()
    // const {id} = useParams()
  
    // useEffect(() => {
    //   if(api_key){
    //     dispatch(getDevelopmentAction(id))
    //     dispatch(getUnitsDevelopmentAction(id))
    //   }
    // },[api_key])

    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              name
              global_whatsapp
          }
    }`)
    const location = useLocation()

    return !loading ? ( 
        <GridGalleryModal 
            typeProp={development}
            loadOpt={loading}
            slider={slider}
            rsName={realEstate?.name}
            modalWpLocation={location?.href}
            modalWpNumber={realEstate?.global_whatsapp?.replaceAll("-", "")}
            emailSubject={getFakeAddress(development)} />
    )
    :
    <div>
      <Loading absolute={true}/>
    </div>
}

export default connect(state => ({
    development: state.developments.development,
    loading: state.developments.loading,
    loading_unities: state.developments.loading_unities,
    api_key: state.settings.keys.tokko
}),null)(Main);